#root {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
