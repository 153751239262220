.terminal {
    border-radius: 0px;
    background-color: black;
    font-size: 16px;
    border: 2px solid grey;
}

.terminal .toolbar {
    background-color: white;
    padding: 0.5em;
}

.terminal .toolbar .buttons {
    float: right;
}

.terminal .workspace {
    padding: 0.5em 0;
    color: white;
    font-family: "Lucida Console", Monaco, monospace;
}